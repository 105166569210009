import React from 'react';
import styled from 'styled-components';
import { between } from 'polished';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import SEO from '../components/seo';
import Container from '../components/container';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const Article = styled(Container)`
	max-width: 700px;
	line-height: ${between('22px', '26px')};

	* + * {
		margin-top: 23px;
	}

	h2 {
		font-size: ${between('26px', '30px')};
		line-height: ${between('26px', '40px')};
	}

	h3 {
		font-size: ${between('22px', '24px')};
		line-height: ${between('28px', '36px')};
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Playfair Display', serif;
		font-weight: 700;

		& + * {
			margin-bottom: 14px
			margin-top: -10px;
		}
	}

	ul,
	ol {
		padding-left: 20px;
		margin-bottom: 35px;

		li {
			margin-top: 10px;
		}
	}
`;

const PrivacyPolicyPage = () => (
	<Page>
		<SEO title="Polityka prywatności" description="Chronimy prywatność naszych użytkowników. Zachęcamy do zapoznania się ze stosowaną na portalu polityką prywatności." canonical="polityka-prywatnosci" />
		<StyledHeaderSection hero="/hero-profile.jpg">
			<HeaderFront>
				<Navigation />
				<Container>
					<HeaderHeading>Polityka prywatności</HeaderHeading>
				</Container>
			</HeaderFront>
		</StyledHeaderSection>

		<Article>
			<p>W przygotowaniu...</p>
		</Article>
	</Page>
);

export default PrivacyPolicyPage;
